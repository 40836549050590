import { MegaMenuFeature } from 'src/@vex/components/mega-menu/mega-menu.component';
import { NavigationItem } from 'src/@vex/interfaces/navigation-item.interface';
import { AccountCashesComponent } from 'src/app/pages/accounting/account/account-cashes/account-cashes.component';
import { DailyComponent } from 'src/app/pages/accounting/daily/daily.component';
import { ServiceProviderDebitsComponent } from 'src/app/pages/accounting/debit/service-provider-debits/service-provider-debits.component';
import { EnrollmentDebitsComponent } from 'src/app/pages/accounting/debit/enrollment-debits/enrollment-debits.component';
import { DebitComponent } from 'src/app/pages/accounting/debit/debit.component';
import { InvoiceComponent } from 'src/app/pages/accounting/invoice/invoice.component';
import { InvoiceFormComponent } from 'src/app/pages/accounting/invoice/invoice-form/invoice-form.component';
import { BranchBalancesComponent } from 'src/app/pages/accounting/account/branch-balances/branch-balances.component';
import { InvoiceInformationComponent } from 'src/app/pages/accounting/invoice-information/invoice-information.component';
import { SchoolBalancesComponent } from 'src/app/pages/accounting/debit/school-balances/school-balances.component';
import { CreditFilterComponent } from 'src/app/pages/accounting/credit/credit-filter/credit-filter.component';
import { CreditServiceProviderOperationComponent } from 'src/app/pages/accounting/credit/credit-service-provider-operation/credit-service-provider-operation.component';
import { CreditServiceProviderEducationComponent } from 'src/app/pages/accounting/credit/credit-service-provider-education/credit-service-provider-education.component';
import { CreditProgramFeeComponent } from 'src/app/pages/accounting/credit/credit-program-fee/credit-program-fee.component';
import { CreditSchoolCommissionComponent } from 'src/app/pages/accounting/credit/credit-school-commission/credit-school-commission.component';

export const navigations: {
  accounting: NavigationItem[];
  education: NavigationItem[];
  settings: NavigationItem[];
} = {
  accounting: [
    {
      type: 'tab',
      label: 'Gunluk',
      tabInterface: {
        label: 'Gunluk',
        component: DailyComponent,
        id: 'daily',
        singleTab: true
      },
      url: 'accounting/daily',
      icon: 'mat:insights',
      claims: ['CanSeeDailyTransactions']
    },
    {
      type: 'tab',
      claims: ['CanSeeBalancesInOtherAccounts'],
      tabInterface: {
        label: 'Hesap Raporu',
        component: BranchBalancesComponent,
        id: 'accounting/branch-balances',
        singleTab: true
      },
      label: 'Hesap Raporu',
      url: '/accounting/branch-balances',
      icon: 'mat:account_balance'
    },
    {
      type: 'tab',
      tabInterface: {
        label: 'Kasa Nakit Raporu',
        component: AccountCashesComponent,
        id: 'accounting/account-cashes',
        singleTab: true
      },
      label: 'Kasa Nakit Raporu',
      url: '/accounting/account-cashes',
      icon: 'mat:account_balance',
      claims: ['CanSeeAccountCashes']
    },
    {
      type: 'dropdown',
      label: 'Borclar',
      icon: 'mat:payments',
      claims: ['CanDebitAndCredit'],
      children: [
        {
          type: 'tab',
          tabInterface: {
            label: 'Borc Hareketleri',
            component: DebitComponent,
            id: 'debits',
            singleTab: true
          },
          label: 'Borc Hareketleri',
          url: '/accounting/debits'
        },
        {
          type: 'tab',
          tabInterface: {
            label: 'Servis Saglayici Borclari',
            component: ServiceProviderDebitsComponent,
            id: 'debit-service-provider-report',
            singleTab: true
          },
          label: 'Servis Saglayici',
          url: '/accounting/debit-service-provider-report'
        }
      ]
    },
    {
      type: 'dropdown',
      label: 'Alacaklar',
      icon: 'mat:credit_card',
      claims: ['CanDebitAndCredit'],
      children: [
        {
          type: 'tab',
          tabInterface: {
            label: 'Alacak Hareketleri',
            component: CreditFilterComponent,
            id: 'credits',
            singleTab: true
          },
          label: 'Alacak Hareketleri',
          url: '/accounting/credit-filter'
        },
        {
          type: 'tab',
          tabInterface: {
            label: 'Program Ücreti',
            component: CreditProgramFeeComponent,
            id: 'credit-student-report',
            singleTab: true
          },
          label: 'Program Ücreti',
          url: 'credit-student-report'
        },
        {
          type: 'tab',
          tabInterface: {
            label: 'Okul Komisyonu',
            component: CreditSchoolCommissionComponent,
            id: 'credit-school-report',
            singleTab: true
          },
          label: 'Okul Komisyonu',
          url: 'credit-school-report'
        },
        {
          type: 'tab',
          tabInterface: {
            label: 'Servis Sağlayıcı | Eğitim',
            component: CreditServiceProviderEducationComponent,
            id: 'credit-service-provider-education-report',
            singleTab: true
          },
          label: 'Servis Sağlayıcı | Eğitim',
          url: 'credit-service-provider-education-report'
        },
        {
          type: 'tab',
          tabInterface: {
            label: 'Servis Sağlayıcı | Operasyon',
            component: CreditServiceProviderOperationComponent,
            id: 'credit-service-provider-operation-report',
            singleTab: true
          },
          label: 'Servis Sağlayıcı | Operasyon',
          url: 'credit-service-provider-operation-report'
        }
      ]
    },
    {
      type: 'dropdown',
      label: 'Fatura',
      icon: 'mat:receipt',
      claims: ['CanInvoice'],
      children: [
        {
          type: 'tab',
          tabInterface: {
            label: 'Faturalar',
            component: InvoiceComponent,
            id: 'accounting/invoice',
            singleTab: true
          },
          label: 'Faturalar',
          url: '/accounting/invoice'
        },
        {
          type: 'tab',
          tabInterface: {
            label: 'Yeni Fatura',
            component: InvoiceFormComponent,
            id: 'accounting/invoice-new',
            singleTab: true
          },
          label: 'Yeni Fatura',
          url: '/accounting/invoice-new'
        },
        {
          type: 'tab',
          tabInterface: {
            label: 'Fatura Bilgileri',
            component: InvoiceInformationComponent,
            id: 'invoice-informations',
            singleTab: true
          },
          label: 'Fatura Bilgileri',
          url: '/accounting/invoice-informations',
          icon: 'mat:receipt'
        },
        {
          type: 'tab',
          tabInterface: {
            label: 'Okul Bakiyeleri',
            component: SchoolBalancesComponent,
            id: 'school-balances',
            singleTab: true
          },
          label: 'Okul Bakiyeleri',
          url: '/accounting/school-balances'
        }
      ]
    },
    {
      type: 'link',
      label: 'Eski Kayitlar',
      route: '/enrollment/admin-filter',
      icon: 'mat:groups',
      roles: ['Manager']
    },
    {
      type: 'link',
      label: 'Primler',
      route: '/enrollment/premium',
      icon: 'mat:money',
      roles: ['Manager']
    }
  ],
  education: [
    {
      type: 'subheading',
      label: 'EĞİTİM',
      children: [
        {
          type: 'link',
          label: 'Dashboard',
          route: '/dashboard',
          roles: ['Consultant', 'ProgramManager', 'Manager', 'Supervisor'],
          icon: 'mat:insights',
          routerLinkActiveOptions: { exact: true }
        },
        {
          type: 'link',
          label: 'Inquiries',
          route: '/inquiry/filter',
          claims: ['CanEditInquiries'],
          icon: 'mat:contact_mail'
        },
        {
          type: 'link',
          label: 'Report',
          route: '/inquiry/report',
          roles: ['Consultant', 'Manager'],
          icon: 'mat:event_note'
        },
        {
          type: 'link',
          label: 'Follow Ups',
          route: '/inquiry/follow-up/filter',
          roles: ['Manager'],
          icon: 'mat:follow_the_signs'
        },
        {
          type: 'link',
          label: 'Enrollments',
          route: '/enrollment/filter',
          claims: ['CanEditEnrollments'],
          icon: 'mat:assignment_ind'
        },
        {
          type: 'link',
          label: 'Enrollment Placements',
          route: '/enrollment/placement-application/filter',
          roles: ['Manager', 'Supervisor'],
          icon: 'mat:assignment_turned_in'
        },
        {
          type: 'link',
          label: 'Corporate Inquiries',
          route: '/corporate-inquiry/filter',
          claims: ['CanEditCorporateInquiries'],
          icon: 'mat:contact_mail'
        },
        {
          type: 'link',
          label: 'Form Mockup',
          route: '/inquiry/mockup/filter',
          icon: 'mat:contact_mail'
        }
      ]
    }
  ],
  settings: [
    {
      type: 'subheading',
      label: 'AYARLAR',
      children: []
    },
    {
      type: 'dropdown',
      label: 'Kullanıcı Yönetimi',
      icon: 'mat:group',
      claims: ['CanEditUsers'],
      children: [
        {
          type: 'link',
          label: 'Kullanıcılar',
          route: '/identity/user/filter',
          icon: 'mat:group'
        },
        {
          type: 'link',
          label: 'Roller',
          route: '/identity/role/filter',
          icon: 'mat:psychology'
        },
        {
          type: 'link',
          label: 'Program Yoneticileri',
          route: '/identity/program-managers',
          claims: ['CanEditProgramManagers'],
          icon: 'mat:engineering'
        },
        {
          type: 'link',
          label: 'Danisman Program Ayarlari',
          route: '/identity/consultants-program-types',
          claims: ['CanEditColsultantSettings'],
          icon: 'mat:support_agent'
        },
        {
          type: 'link',
          label: 'Danisman Hedefleri',
          route: '/identity/consultant-targets',
          claims: ['CanEditColsultantSettings'],
          icon: 'mat:support_agent'
        }
      ]
    },

    {
      type: 'dropdown',
      label: 'Primler',
      claims: ['CanEditPremiums'],
      icon: 'mat:workspace_premium',
      children: [
        {
          type: 'link',
          label: 'Primler',
          route: '/premium/report'
        },
        {
          type: 'link',
          label: 'Prim Ayarlar',
          route: '/premium/preferences'
        }
      ]
    },
    {
      type: 'dropdown',
      label: 'Lokasyonlar',
      claims: ['CanEditLocations'],
      icon: 'mat:public',
      children: [
        {
          type: 'link',
          label: 'Ofisler',
          route: '/location/branch/filter',
          icon: 'mat:business'
        },
        {
          type: 'link',
          label: 'Ulkeler',
          route: '/location/country/filter',
          icon: 'mat:public'
        },
        {
          type: 'link',
          label: 'Sehirler',
          route: '/location/city/filter',
          icon: 'mat:location_city'
        }
      ]
    },
    {
      type: 'dropdown',
      label: 'Ayarlar',
      icon: 'mat:settings',
      claims: ['CanEditSettings'],
      children: [
        {
          type: 'link',
          label: 'Kasalar',
          route: '/settings/account-case/filter',
          icon: 'mat:account_balance_wallet'
        },
        {
          type: 'link',
          label: 'Hesaplar',
          route: '/settings/account/filter',
          icon: 'mat:account_balance_wallet'
        },
        {
          type: 'link',
          label: 'Para Birimleri',
          route: '/settings/currency/filter',
          icon: 'mat:attach_money'
        },
        {
          type: 'link',
          label: 'Servis Saglayicilar',
          route: '/settings/service-provider/filter',
          icon: 'mat:home_repair_service'
        },
        {
          type: 'link',
          label: 'Temsilciler',
          route: '/settings/study-abroad-representative/filter',
          icon: 'mat:supervised_user_circle'
        },
        {
          type: 'link',
          label: 'Yurtdisi Egitim Noktalari',
          route: '/settings/study-abroad-point/filter',
          icon: 'mat:add_location'
        },
        {
          type: 'link',
          label: 'Whitelist Ipler',
          route: '/settings/whitelist-ip/filter',
          icon: 'mat:location_on'
        }
      ]
    },
    {
      type: 'dropdown',
      label: 'Okullar',
      icon: 'mat:apartment',
      claims: ['CanEditSchools'],
      children: [
        {
          type: 'link',
          label: 'Okullar',
          route: '/schools/school/filter',
          icon: 'mat:apartment'
        },
        {
          type: 'link',
          label: 'Okul Subeleri',
          route: '/schools/school-branch/filter',
          icon: 'mat:domain_disabled'
        },
        {
          type: 'dropdown',
          label: 'Ayarlar',
          icon: 'mat:room_preferences',
          children: [
            {
              type: 'link',
              label: 'Programlar',
              route: '/schools/program/filter',
              icon: 'mat:assignment'
            },
            {
              type: 'link',
              label: 'Konaklamalar',
              route: '/schools/accommodation/filter',
              icon: 'mat:hotel'
            },
            {
              type: 'link',
              label: 'Okul Prim Tipleri',
              route: '/schools/school-premium-type/filter',
              icon: 'mat:hotel'
            }
          ]
        }
      ]
    }
  ]
};

export const megamenu: MegaMenuFeature[] = [
  {
    label: 'Muhasabe',
    icon: 'mat:insights',
    url: 'accounting',
    id: 'accounting',
    sidenav: navigations.accounting,
    pageLoadType: 'tab',
    claims: ['CanSeeAccountingService']
  },
  {
    label: 'Eğitim',
    icon: 'mat:contact_mail',
    url: 'education',
    id: 'education',
    sidenav: navigations.education,
    pageLoadType: 'page',
    claims: ['CanSeeEducationService']
  },
  {
    label: 'Ayarlar',
    icon: 'mat:settings',
    url: 'settings',
    id: 'settings',
    sidenav: navigations.settings,
    pageLoadType: 'page',
    claims: ['CanSeeSettings']
  }
];
