import { Component, ContentChildren, Input, OnInit, QueryList, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { CustomFormlyFieldConfig } from 'src/app/components/utclub-formly/formly/custom-formly-field-config';
import { PagedResponse } from 'src/app/models/response.model';
import { ApiService } from 'src/app/services/api.service';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'utclub-student-debits-by-service-provider',
  templateUrl: './student-debits-by-service-provider.component.html',
  styleUrls: ['./student-debits-by-service-provider.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
    fadeInUp400ms,
    stagger40ms
  ]
})
export class StudentDebitsByServiceProviderComponent implements OnInit {
  @Input() serviceProviderId: number;

  dataSource: MatTableDataSource<any> | null = null;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  loading: boolean = false;
  columns: string[] = ['enrollmentName', 'totalDebit', 'totalPayment', 'debitStatus', 'expand'];
  subColumns: string[] = ['date', 'type', 'relatedBranch', 'executorUser', 'account', 'amount', 'detail', 'note'];

  pageSize: number = 50;
  pageIndex: number = 0;

  expandedElement: any | null;
  constructor(
    private matDialog: MatDialog,
    private apiService: ApiService
  ) {}

  ngAfterViewInit(): void {
    this.paginator.pageIndex = this.pageIndex;
    this.paginator.pageSize = this.pageSize;
    this.loadData();
  }

  ngOnInit(): void {
    this.filterModel.serviceProviderId = this.serviceProviderId;
  }

  loadData() {
    this.apiService
      .post<PagedResponse<any[]>>('accounting-service/debit/enrollment-debits-by-service-provider', {
        ...this.filterModel,
        page: this.paginator?.pageIndex ?? this.pageIndex,
        pageSize: this.paginator?.pageSize ?? this.pageSize
      })
      .subscribe((response) => {
        response.data.forEach((item: any) => {
          item.transactionsDataSource = new MatTableDataSource(item.transactions);
        });

        this.dataSource = new MatTableDataSource(response.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
  }
  pageChanged(event) {
    this.loadData();
  }

  filterOpened: boolean = false;
  filterModel: any = {};
  filterForm = new FormGroup({});
  filterFields: CustomFormlyFieldConfig[] = null;
  filterToggle() {
    if (!this.filterFields) {
      this.filterFields = [
        {
          fieldGroupClassName: 'grid grid-cols-4 gap-4',
          fieldGroup: [
            {
              type: 'datepicker',
              key: 'startDate',
              props: {
                label: 'Baslangic Tarihi',
                placeholder: ''
              }
            },
            {
              type: 'datepicker',
              key: 'endDate',
              props: {
                label: 'Bitis Tarihi',
                placeholder: ''
              }
            },
            {
              type: 'input',
              key: 'enrollmentId',
              props: {
                label: 'Ogrenci ID',
                placeholder: ''
              }
            }
          ]
        }
      ];
    }
    this.filterOpened = !this.filterOpened;
  }
  clearFilter() {
    this.filterModel = {};
    var s: number[] = [];
    s;
    s.filter;
    this.loadData();
  }
}
