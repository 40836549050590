<div class="card overflow-auto">
    <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
      <h2 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none">
        <span> Ogrenci Borclari </span>
      </h2>
  
      <utclub-button buttonType="button" [label]="'Filtre'" (click)="filterToggle()"
        [icon]="filterOpened ? 'mat:keyboard_arrow_down' : 'mat:keyboard_arrow_right'"></utclub-button>
  
      <button (click)="loadData()" class="ml-4 flex-none" mat-icon-button matTooltip="Yenile" type="button">
        <mat-icon svgIcon="mat:refresh"></mat-icon>
      </button>
  
      <span class="flex-1"></span>
    </div>
  
    <div *ngIf="filterOpened" @fadeInUp class="p-4">
      <utclub-formly [form]="filterForm" [fields]="filterFields" [model]="filterModel">
      </utclub-formly>
      <div>
        <utclub-button buttonType="button" [label]="'Filtrele'" (click)="loadData()" class="mr-2"></utclub-button>
        <utclub-button buttonType="button" [label]="'Temizle'" (click)="clearFilter()">
        </utclub-button>
      </div>
    </div>
  
    <mat-progress-bar *ngIf="loading" mode="indeterminate" color="primary"></mat-progress-bar>
  
    <table *ngIf="!loading && dataSource" [dataSource]="dataSource" class="w-full" mat-table multiTemplateDataRows>
  
      <ng-container matColumnDef="enrollmentName">
        <th mat-header-cell *matHeaderCellDef> Ogrenci </th>
        <td mat-cell *matCellDef="let element">
  
          {{element.enrollmentName}}<br>
          #{{element.enrollmentId}}
  
  
        </td>
      </ng-container>
  
      <ng-container matColumnDef="totalDebit">
        <th mat-header-cell *matHeaderCellDef> Borc </th>
        <td mat-cell *matCellDef="let element">
  
          <div class="flex items-center gap-4">
            <div class="w-8 h-8 rounded text-red bg-red/10 flex-none flex items-center justify-center">
              <mat-icon class="icon-sm" svgIcon="mat:arrow_drop_down"></mat-icon>
            </div>
  
            <p class="text-secondary m-0">
              <span class="font-medium text-red"> {{ element.totalDebit | currency:element.currencyId | async  }} </span>
            </p>
          </div>
  
        </td>
      </ng-container>
  
      <ng-container matColumnDef="totalPayment">
        <th mat-header-cell *matHeaderCellDef> Yapilan Odeme </th>
        <td mat-cell *matCellDef="let element">
  
          <div class="flex items-center gap-4">
            <div class="w-8 h-8 rounded text-green bg-green/10 flex-none flex items-center justify-center">
              <mat-icon class="icon-sm" svgIcon="mat:arrow_drop_up"></mat-icon>
            </div>
  
            <p class="text-secondary m-0">
              <span class="font-medium text-green"> {{ element.totalPayment | currency:element.currencyId | async  }} </span>
            </p>
          </div>
  
        </td>
      </ng-container>
  
      <ng-container matColumnDef="debitStatus">
        <th mat-header-cell *matHeaderCellDef> Borc Durumu </th>
        <td mat-cell *matCellDef="let element">
  
  
          <div [ngSwitch]="element.debitStatus">
            <div *ngSwitchCase="1" class="rounded px-2 text-green bg-green/10" style="display: inline-block;">
              Odeme Tamamlandi
            </div>
            <div *ngSwitchCase="2" class="rounded px-2 text-amber bg-amber/10" style="display: inline-block;">
              Odeme Bekleniyor
            </div>
            <div *ngSwitchCase="3" class="rounded px-2 text-red bg-red/10" style="display: inline-block;">
              Hic Odeme Yapilmadi
            </div>
          </div>
  
        </td>
      </ng-container>
  
      <ng-container matColumnDef="totalServiceProviderDebit">
        <th mat-header-cell *matHeaderCellDef> Servis Saglayici Borc </th>
        <td mat-cell *matCellDef="let element">
  
          <div class="flex items-center gap-4">
            <div class="w-8 h-8 rounded text-warn bg-warn/10 flex-none flex items-center justify-center">
              <mat-icon class="icon-sm" svgIcon="mat:arrow_drop_up"></mat-icon>
            </div>
  
            <p class="text-secondary m-0">
              <span class="font-medium text-warn"> {{ element.totalServiceProviderDebit | currency:element.currencyId | async  }} </span>
            </p>
          </div>
  
        </td>
      </ng-container>
  
      <ng-container matColumnDef="totalServiceProviderPayment">
        <th mat-header-cell *matHeaderCellDef> Servis Saglayici Odeme </th>
        <td mat-cell *matCellDef="let element">
  
          <div class="flex items-center gap-4">
            <div class="w-8 h-8 rounded text-primary bg-primary/10 flex-none flex items-center justify-center">
              <mat-icon class="icon-sm" svgIcon="mat:arrow_drop_up"></mat-icon>
            </div>
  
            <p class="text-secondary m-0">
              <span class="font-medium text-primary"> {{ element.totalServiceProviderPayment | currency:element.currencyId | async  }} </span>
            </p>
          </div>
  
        </td>
      </ng-container>
  
      <ng-container matColumnDef="serviceProviderDebitStatus">
        <th mat-header-cell *matHeaderCellDef> Servis Saglayici Borc Durumu </th>
        <td mat-cell *matCellDef="let element">
  
  
          <div [ngSwitch]="element.serviceProviderDebitStatus">
            <div *ngSwitchCase="1" class="rounded px-2 text-green bg-green/10" style="display: inline-block;">
              Odeme Tamamlandi
            </div>
            <div *ngSwitchCase="2" class="rounded px-2 text-amber bg-amber/10" style="display: inline-block;">
              Odeme Bekleniyor
            </div>
            <div *ngSwitchCase="3" class="rounded px-2 text-red bg-red/10" style="display: inline-block;">
              Hic Odeme Yapilmadi
            </div>
          </div>
  
        </td>
      </ng-container>
  
      <ng-container matColumnDef="expand">
        <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button aria-label="expand row"
            (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
            <mat-icon svgIcon="mat:keyboard_arrow_down" *ngIf="expandedElement !== element"></mat-icon>
            <mat-icon svgIcon="mat:keyboard_arrow_up" *ngIf="expandedElement === element"></mat-icon>
          </button>
        </td>
      </ng-container>
  
  
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="columns.length + 1">
          <div class="example-element-detail" [class.mt-2]="element == expandedElement"
            [class.mb-2]="element == expandedElement"
            [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
  
            <table [dataSource]="element.transactionsDataSource" class="w-full" mat-table>
  
              <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef> Tarih </th>
                <td mat-cell *matCellDef="let element">
                  {{element.date | date:'dd-MM-yyyy HH:mm':'+300'}}
                </td>
              </ng-container>
  
              <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef> Islem Tipi </th>
                <td mat-cell *matCellDef="let element">
                  <div [ngSwitch]="element.type">
                    <div *ngSwitchCase="1" class="rounded px-2 text-green bg-green/10" style="display: inline-block;">
                      Tahsilat
                    </div>
                    <div *ngSwitchCase="2" class="rounded px-2 text-red bg-red/10" style="display: inline-block;"> Borc
                    </div>
                    <div *ngSwitchCase="3" class="rounded px-2 text-amber bg-amber/10" style="display: inline-block;"> Odeme
                    </div>
                    <div *ngSwitchCase="4" class="rounded px-2 text-red bg-red/10" style="display: inline-block;"> Servis Saglayici Borc
                    </div>
                    <div *ngSwitchCase="5" class="rounded px-2 text-primary bg-primary/10" style="display: inline-block;"> Servis Saglayici Odeme
                    </div>
                  </div>
                </td>
              </ng-container>
  
              <ng-container matColumnDef="relatedBranch">
                <th mat-header-cell *matHeaderCellDef> Ofis </th>
                <td mat-cell *matCellDef="let element"> {{element.relatedBranchName}} </td>
              </ng-container>
  
              <ng-container matColumnDef="executorUser">
                <th mat-header-cell *matHeaderCellDef> İslemi Yapan </th>
                <td mat-cell *matCellDef="let element"> {{element.executorUserName}} </td>
              </ng-container>
  
              <ng-container matColumnDef="account">
                <th mat-header-cell *matHeaderCellDef> Hesap </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.accountName }}
                </td>
              </ng-container>
  
              <ng-container matColumnDef="detail">
                <th mat-header-cell *matHeaderCellDef> Detay </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.detail }}
                </td>
              </ng-container>
  
              <ng-container matColumnDef="note">
                <th mat-header-cell *matHeaderCellDef> Not </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.note }}
                </td>
              </ng-container>
  
              <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef> Miktar </th>
                <td mat-cell *matCellDef="let subElement">
                  {{subElement.amount | currency:subElement.currencyId | async}}
                </td>
              </ng-container>
  
              <tr mat-header-row *matHeaderRowDef="subColumns"></tr>
              <tr mat-row @fadeInUp *matRowDef="let subElement; columns: subColumns;"
                class="hover:bg-hover trans-ease-out cursor-pointer">
              </tr>
            </table>
  
          </div>
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr mat-row @fadeInUp *matRowDef="let element; columns: columns;"
        class="hover:bg-hover trans-ease-out cursor-pointer example-element-row"
        [class.example-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>
  
    <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 20, 50]" (page)="pageChanged($event)" class="sticky left-0">
    </mat-paginator>
  </div>