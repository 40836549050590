<div class="card overflow-auto">
  <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
    <h2 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none">
      <span> Servis Sağlayıcı | Eğitim </span>
    </h2>

    <div class="bg-foreground rounded-full border px-2 max-w-[200px] flex-auto flex items-center ml-4">
      <mat-select
        *ngIf="serviceProviders | async as serviceProviderList"
        [(ngModel)]="serviceProviderId"
        (valueChange)="onChangeServiceProvider($event)">
        <mat-option *ngFor="let userType of serviceProviderList" [value]="userType.id">
          {{ userType.name }}
        </mat-option>
      </mat-select>
    </div>

    <button (click)="loadData()" class="ml-4 flex-none" mat-icon-button matTooltip="Yenile" type="button">
      <mat-icon svgIcon="mat:refresh"></mat-icon>
    </button>

    <span class="flex-1"></span>
  </div>

  <mat-progress-bar *ngIf="loading" mode="indeterminate" color="primary"></mat-progress-bar>

  <table *ngIf="!loading && data">
    <thead>
      <tr>
        <th>Ofis</th>
        <th>Alacak</th>
        <th>Tahsilat</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let item of data">
        <tr (click)="item.expanded = !item.expanded">
          <td>
            #{{ item.enrollmentId }} {{ item.enrollmentName }} | 
            {{ item.currencyId | entity: 'Currency' | async }}
          </td>
          <td>
            <p class="text-secondary m-0">
              <span class="font-medium text-red"> {{ item.totalCredit | currency: item.currencyId | async }} </span>
            </p>
          </td>
          <td>
            <p class="text-secondary m-0">
              <span class="font-medium text-green"> {{ item.totalReceipt | currency: item.currencyId | async }} </span>
            </p>
          </td>
        </tr>
        <tr *ngIf="item.expanded">
          <td colspan="3">
            <table>
              <thead>
                <tr>
                  <th>Tarih / No</th>
                  <th>Miktar</th>
                  <th>Not</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let transaction of item.transactions">
                  <td class="text-center">
                    #{{ transaction.id }} <br />
                    {{ transaction.date | date: 'dd-MM-yyyy HH:mm' : '+300' }}
                  </td>
                  <td>{{ transaction.amount | currency: transaction.currencyId | async }}</td>
                  <td>{{ transaction.note }}</td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr>
        <td><b>Toplam</b></td>
        <td>
          <p class="text-secondary m-0">
            <span class="font-medium text-red"> {{ totalCredit | currency: 0 | async }} </span>
          </p>
        </td>
        <td>
          <p class="text-secondary m-0">
            <span class="font-medium text-green"> {{ totalReceipt | currency: 0 | async }} </span>
          </p>
        </td>
      </tr>
    </tfoot>
  </table>
</div>
