import { AfterContentInit, Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { UtclubFormComponent, UtclubFormInterface } from 'src/app/components/utclub-form/utclub-form.component';
import { CustomFormlyFieldConfig } from 'src/app/components/utclub-formly/formly/custom-formly-field-config';
import { Enums } from 'src/app/services/enums';
import { EventBusService } from 'src/app/services/event-bus.service';
import { LookupService } from 'src/app/services/lookup.service';

@Component({
  selector: 'vex-debit-form',
  template: `
    <utclub-modal title="Kasa">
      <utclub-form *ngIf="formInterface" [form]="formInterface" [matDialogRef]="matDialogRef"></utclub-form>
    </utclub-modal>
  `
})
export class DebitFormComponent implements AfterContentInit, OnDestroy {
  enrollmentSubs: Subscription;
  debitTypeEnrollmentSubs: Subscription;
  schoolId: number;

  formInterface: UtclubFormInterface;
  matDialogRef: MatDialogRef<any>;

  @ViewChild(UtclubFormComponent) form: UtclubFormComponent;
  constructor(
    @Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<DebitFormComponent>,
    private eventBusService: EventBusService,
    private lookup: LookupService
  ) {
    this.matDialogRef = dialogRef;
  }

  ngAfterContentInit(): void {
    var id = this.defaults?.id;
    this.formInterface = {
      fields: [
        {
          key: 'debitType',
          type: 'big-radio',
          props: {
            label: 'İslem Tipi',
            placeholder: '',
            required: true,
            options: [{ value: 3, label: 'Servis Saglayici' }]
          }
        },
        //Student
        {
          key: 'enrollmentId',
          type: 'enrollment-field',
          props: {
            label: 'Ogrenci',
            placeholder: '',
            required: false
          },
          expressions: { hide: '!model.debitType' }
        },
        {
          key: 'debitTypeEnrollment',
          type: 'enum-select',
          props: {
            label: 'Borclandirma Turu',
            placeholder: '',
            required: true,
            enum: Enums.DebitTypeEnrollment
          },
          expressions: {
            hide: 'model.debitType != 1'
          }
        },
        {
          type: '#school',
          props: {
            required: true
          },
          expressions: {
            hide: '!(model.debitType == 5 || (model.debitType == 1 && model.debitTypeEnrollment == 1))'
          }
        },
        //Staff
        {
          type: '#user',
          key: 'staffId',
          props: {
            label: 'Personel'
          },
          expressions: {
            hide: 'model.debitType != 2'
          }
        },
        // Service Provider
        {
          type: '#serviceProvider',
          props: {
            required: true
          },
          expressions: {
            hide: 'model.debitType != 3'
          }
        },
        // School
        // Related Branch
        {
          key: 'relatedBranchId',
          type: '#branch',
          props: {
            required: true
          },
          expressions: {
            hide: 'model.debitType != 6'
          }
        },
        //All
        {
          key: 'amount',
          type: 'input',
          props: {
            label: 'Tutar',
            required: true
          },
          expressions: {
            hide: 'model.debitType == null'
          }
        },
        {
          type: '#currency',
          props: {
            required: true
          },
          expressions: {
            hide: 'model.debitType == null'
          }
        },
        {
          key: 'note',
          type: 'textarea',
          props: {
            label: 'Not',
            placeholder: '',
            required: false,
            rows: 3
          },
          expressions: {
            hide: 'model.debitType == null'
          }
        }
      ],
      submitMethod: !id ? 'post' : 'put',
      submitUrl: !id ? 'accounting-service/debit' : 'accounting-service/debit/' + id,
      loadDataUrl: !id ? null : 'accounting-service/debit/' + id
    };
    this.enrollmentSubs = this.eventBusService.on('enrollment-loaded', (data) => {
      this.schoolId = data.schoolId;
      if (this.form.model.debitType == 1 && this.form.model.debitTypeEnrollment == 1) {
        this.form.model.schoolId = this.schoolId;
        this.form.formGroup.patchValue({ schoolId: this.schoolId });
      }
    });
    this.debitTypeEnrollmentSubs = this.eventBusService.on('debitTypeEnrollment', (data) => {
      if (this.form.model.debitType == 1 && this.form.model.debitTypeEnrollment == 1 && this.schoolId) {
        this.form.model.schoolId = this.schoolId;
        this.form.formGroup.patchValue({ schoolId: this.schoolId });
      }
    });
  }

  ngOnDestroy(): void {
    this.enrollmentSubs.unsubscribe();
    this.debitTypeEnrollmentSubs.unsubscribe();
  }
}
