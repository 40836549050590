import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AccountDto } from 'src/app/api/models/accountDto';
import { UtclubFormComponent, UtclubFormInterface } from 'src/app/components/utclub-form/utclub-form.component';
import { CustomFormlyFieldConfig } from 'src/app/components/utclub-formly/formly/custom-formly-field-config';
import { AuthService } from 'src/app/services/auth.service';
import { EventBusService, EventData } from 'src/app/services/event-bus.service';
import { LookupService } from 'src/app/services/lookup.service';

@Component({
  selector: 'vex-exchange-form',
  template: `
    <utclub-modal title="Doviz">
      <utclub-form *ngIf="formInterface" [form]="formInterface" [matDialogRef]="matDialogRef"></utclub-form>
    </utclub-modal>
  `
})
export class ExchangeFormComponent implements AfterContentInit, AfterViewInit, OnDestroy {
  formInterface: UtclubFormInterface;
  matDialogRef: MatDialogRef<any>;

  enrollmentSubs: Subscription;
  fromAccountSubs: Subscription;
  accounts: AccountDto[];

  toAccountField: CustomFormlyFieldConfig = {
    key: 'toAccountId',
    type: 'select',
    props: {
      label: 'Su kasaya*',
      required: true,
      disabled: true,
      options: []
    }
  };
  fields: CustomFormlyFieldConfig[] = [
    {
      key: 'date',
      type: 'datepicker',
      defaultValue: new Date(),
      props: {
        label: 'Tarih',
        placeholder: '',
        required: false
      },
      expressions: {
        hide: (field: CustomFormlyFieldConfig) => {
          return !this.authService.isInRole('Manager');
        }
      }
    },
    {
      key: 'relatedBranchId',
      type: '#branch',
      props: {
        required: true
      }
    },
    {
      key: 'fromAccountId',
      type: '#availableTransactionAccountField',
      props: {
        label: 'Su kasadan*',
        required: true
      }
    },
    {
      key: 'fromAccountAmount',
      type: 'input',
      props: {
        label: 'Tutar',
        placeholder: '',
        required: true
      }
    },
    this.toAccountField,
    {
      key: 'toAccountAmount',
      type: 'input',
      props: {
        label: 'Tutar',
        placeholder: '',
        required: true
      }
    },
    {
      type: '#enrollment'
    },
    {
      key: 'note',
      type: 'textarea',
      props: {
        label: 'Not',
        placeholder: '',
        required: false,
        rows: 3
      }
    },
    {
      key: 'isVirtualTransaction',
      type: 'checkbox',
      props: {
        label: 'Sanal İslem mi?',
        placeholder: '',
        required: false
      },
      defaultValue: false
    }
  ];

  @ViewChild(UtclubFormComponent) form: UtclubFormComponent;
  constructor(
    @Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<ExchangeFormComponent>,
    private eventBusService: EventBusService,
    private authService: AuthService,
    private lookup: LookupService,
    private cdr: ChangeDetectorRef
  ) {
    this.matDialogRef = dialogRef;
  }

  ngAfterContentInit(): void {
    var id = this.defaults?.id;
    this.formInterface = {
      fields: this.fields,
      submitMethod: !id ? 'post' : 'put',
      submitUrl: !id ? 'accounting-service/exchange' : 'accounting-service/exchange/' + id,
      loadDataUrl: !id ? null : 'accounting-service/exchange/' + id
    };
    this.enrollmentSubs = this.eventBusService.on('enrollment-loaded', (data) => {
      this.form.model.relatedBranchId = data.branchId;
      this.form.formGroup.patchValue({ relatedBranchId: data.branchId });
    });

    this.lookup.accounts$.subscribe((accounts) => {
      this.accounts = accounts;
    });
  }

  ngAfterViewInit(): void {
    this.fromAccountSubs = this.form.formGroup.valueChanges.subscribe((model) => {
      console.warn('fromAccountId', this.accounts);
      var fromAccount = this.accounts.find((x) => x.id === model.fromAccountId);
      var targetAccounts = this.accounts.filter((x) => x.accountCaseId === fromAccount.accountCaseId);

      this.toAccountField.props.disabled = false;
      this.toAccountField.props.options = targetAccounts.map((x) => {
        return { label: x.name, value: x.id };
      });
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.enrollmentSubs.unsubscribe();
    if (this.fromAccountSubs) {
      this.fromAccountSubs.unsubscribe();
    }
  }
}
