import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import jwt_decode from 'jwt-decode';
import { Response } from '../models/response.model';
import { AccessTokenDto } from '../api/models/accessTokenDto';
import { EventBusService, EventData } from './event-bus.service';
import { Router } from '@angular/router';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public user: Observable<User>;
  public token: Observable<string>;
  public refreshToken: Observable<string>;

  public userSubject: BehaviorSubject<User>;
  public tokenSubject: BehaviorSubject<string>;
  public refreshTokenSubject: BehaviorSubject<string>;

  constructor(
    private apiService: ApiService,
    private eventBusService: EventBusService,
    private router: Router
  ) {
    this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
    this.user = this.userSubject.asObservable();

    this.tokenSubject = new BehaviorSubject<string>(localStorage.getItem('token'));
    this.token = this.tokenSubject.asObservable();

    this.refreshTokenSubject = new BehaviorSubject<string>(localStorage.getItem('refreshToken'));
    this.refreshToken = this.refreshTokenSubject.asObservable();
  }

  login(email: string, password: string): Observable<Response<AccessTokenDto>> {
    return this.apiService.post<Response<AccessTokenDto>>(`auth-service/auth/login`, { email, password }).pipe(
      map((response) => {
        if (!response.hasError && response.data.tokenType == 'AccessToken') {
          this.setCredentials(response.data);
        }
        return response;
      })
    );
  }

  postRefreshToken(): Observable<Response<AccessTokenDto>> {
    return this.apiService.post<Response<AccessTokenDto>>(`auth-service/auth/refresh-token`, {
      token: this.tokenSubject.value,
      refreshToken: this.refreshTokenSubject.value
    });
  }

  isInRole(role: string): boolean {
    return this.userValue != null && this.userValue.roles.some((x) => x == role);
  }
  hasClaim(claim: string): boolean {
    var decodedAccessToken = this.getDecodedAccessToken(this.tokenValue);
    return decodedAccessToken[claim] != null;
  }
  hasClaimWithValue(claim: string, value: any): boolean {
    var decodedAccessToken = this.getDecodedAccessToken(this.tokenValue);

    if (typeof value == 'string') {
      return decodedAccessToken[claim] == value;
    }

    return decodedAccessToken[claim]?.includes(value.toString());
  }
  getClaimValue<T>(claim: string): T {
    var decodedAccessToken = this.getDecodedAccessToken(this.tokenValue);
    return decodedAccessToken[claim];
  }
  getClaimValues(claim: string): string[] {
    var decodedAccessToken = this.getDecodedAccessToken(this.tokenValue);
    var value = decodedAccessToken[claim];
    if (value == null) {
      return [];
    }
    if (typeof value == 'string') {
      return [value];
    }
    return value;
  }

  resetPassword(model: any) {
    return this.apiService.post('auth/reset-password', model);
  }

  logout(withReturlUrl: boolean = false) {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    this.userSubject.next(null);
    this.tokenSubject.next('');
    this.refreshTokenSubject.next('');
    this.eventBusService.emit(new EventData('userLoggedOut', null));
    // router.naviage to /auth/login with queryParams returnUrl
    if (withReturlUrl) this.router.navigate(['/auth/login'], { queryParams: { returnUrl: this.router.url } });
    else this.router.navigate(['/auth/login']);
  }

  setCredentials(tokenDto: AccessTokenDto) {
    var decodedAccessToken = this.getDecodedAccessToken(tokenDto.token);
    var user: User = {
      id: decodedAccessToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'],
      firstName: decodedAccessToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'],
      lastName: decodedAccessToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname'],
      email: decodedAccessToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'],
      phoneNumber: decodedAccessToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/mobilephone'],
      branchId: decodedAccessToken['BranchId'],
      branchName: decodedAccessToken['BranchName'],
      title: decodedAccessToken['Title'],
      roles: decodedAccessToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
    };

    //if user.roles is string convert to array
    if (typeof user.roles == 'string') {
      user.roles = [user.roles];
    }

    this.userSubject.next(user);
    this.tokenSubject.next(tokenDto.token);
    this.refreshTokenSubject.next(tokenDto.refreshToken);
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('token', tokenDto.token);
    localStorage.setItem('refreshToken', tokenDto.refreshToken);

    this.eventBusService.emit(new EventData('userLoggedIn', {}));
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  get userValue(): User {
    return this.userSubject.value;
  }
  get tokenValue(): string {
    return this.tokenSubject.value;
  }
  get refreshTokenValue(): string {
    return this.refreshTokenSubject.value;
  }
  get isExpiredValue(): boolean {
    var decodedAccessToken = this.getDecodedAccessToken(this.tokenValue);
    if (decodedAccessToken == null) {
      return true;
    }
    var exp = decodedAccessToken['exp'];
    if (exp == null) {
      return true;
    }
    var date = new Date(0);
    date.setUTCSeconds(exp);
    return date.valueOf() < new Date().valueOf();
  }
}
