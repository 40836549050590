import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import { CustomFormlyFieldConfig } from '../custom-formly-field-config';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject, take, takeUntil } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { HttpEvent, HttpResponse } from '@angular/common/http';
import { Response } from 'src/app/models/response.model';

@Component({
  selector: 'formly-cached-select',
  template: `
    <mat-form-field appearance="outline" style="width: 100%;">
      <mat-label> {{ field.props.label }} </mat-label>

      <mat-select
        #input
        [multiple]="field.props.multiple"
        [required]="field.props.required"
        [formControl]="control"
        [formlyAttributes]="field"
        (opened)="onOpened()">
        <mat-option>
          <ngx-mat-select-search
            [closeSvgIcon]="'mat:close'"
            [formControl]="searchCtrl"
            placeholderLabel="Ara..."></ngx-mat-select-search>
        </mat-option>

        <mat-option *ngIf="loadingItems">
          <div class="flex items-center justify-between">
            <span> Yükleniyor... </span>
            <span>
              <mat-progress-spinner mode="indeterminate" diameter="20"></mat-progress-spinner>
            </span>
          </div>
        </mat-option>

        <mat-option *ngIf="anyErrors" (click)="loadItems()">
          <div class="flex items-center justify-between text-red-500">
            <span>Bir hata oluştu</span>
            <button mat-icon-button>
              <mat-icon svgIcon="mat:refresh"></mat-icon>
            </button>
          </div>
        </mat-option>

        <mat-option *ngIf="!field.props.required && !searchCtrl.value && !loadingItems && !anyErrors" [value]="null">
          Seçiniz
        </mat-option>

        <mat-option *ngFor="let item of filteredItems | async" [value]="item.value">
          {{ item.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  `
})
export class HttpSelectFieldComponent
  extends FieldType<CustomFormlyFieldConfig>
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('input') input: MatSelect;
  searchCtrl = new FormControl();

  items: any[];
  loadingItems: boolean = false;
  anyErrors: boolean = false;

  filteredItems: ReplaySubject<any[]> = new ReplaySubject<any[]>();
  _onDestroy = new Subject<void>();
  constructor(
    private apiService: ApiService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  async ngOnInit() {
    if (!this.field.props.valueProp) {
      this.field.props.valueProp = 'id';
    }
    if (!this.field.props.labelProp) {
      this.field.props.labelProp = 'name';
    }

    if (!this.field.props.http?.parentProp) this.loadItems();

    if (this.field.props.http?.parentProp) {
      this.form.controls[this.field.props.http.parentProp].valueChanges.subscribe((value: any) => {
        if (!value) return;
        this.loadItems();
      });
      if (this.form.controls[this.field.props.http.parentProp].value) {
        this.loadItems();
      }
    }

    this.searchCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterItems();
    });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  ngAfterViewInit(): void {
    this.setInitialValue();
  }

  setInitialValue() {
    this.filteredItems.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
      this.input.compareWith = (o1: any, o2: any) => {
        return o1 && o2 && o1 === o2;
      };
    });
  }

  filterItems() {
    if (!this.items) {
      return;
    }
    // get the search keyword
    let search = this.searchCtrl.value;
    if (!search) {
      this.filteredItems.next(this.items.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredItems.next(this.items.filter((x) => x.label.toLowerCase().indexOf(search) > -1));
  }

  loadItems() {
    if (!this.field.props.http) {
      throw new Error('http prop is required');
    }
    this.loadingItems = true;
    this.anyErrors = false;
    var url = this.field.props.http.url;
    if (this.field.props.http.parentProp) {
      url =
        url +
        '?' +
        this.field.props.http.parentPropQuery +
        '=' +
        this.form.controls[this.field.props.http.parentProp].value;
    }
    this.apiService.request(this.field.props.http.method || 'get', url, this.field.props.http.body).subscribe({
      next: (response: HttpEvent<Response<any>>) => {
        if (response instanceof HttpResponse) {
          var items = this.setItems(response.body.data);
          this.items = items;
          this.filteredItems.next(this.items.slice());
          this.loadingItems = false;
          this.cdr.detectChanges();
        }
      },
      error: (error) => {
        this.anyErrors = true;
        this.loadingItems = false;
        this.cdr.detectChanges();
      },
      complete: () => {
        this.loadingItems = false;
        this.cdr.detectChanges();
      }
    });
  }

  setItems(items: any[]) {
    var mappedItems;
    if (this.field.props.labelFunc) {
      mappedItems = items.map((item: any) => {
        return {
          value: item[this.field.props.valueProp],
          label: this.field.props.labelFunc(item)
        };
      });
    }

    if (this.field.props.labelProp) {
      mappedItems = items.map((item: any) => {
        return {
          value: item[this.field.props.valueProp],
          label: item[this.field.props.labelProp]
        };
      });
    }

    if (!this.field.props.labelFunc && !this.field.props.labelProp) {
      throw new Error('labelFunc or labelProp is required');
    }

    return mappedItems;
  }

  onOpened() {
    this.searchCtrl.setValue('');
  }

  onClosed() {
    console.warn(this.input.selected);
  }

  get control() {
    return this.formControl as FormControl;
  }
}
