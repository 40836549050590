import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { Response } from 'src/app/models/response.model';
import { ApiService } from 'src/app/services/api.service';
import { EventBusService } from 'src/app/services/event-bus.service';
import { LookupService } from 'src/app/services/lookup.service';

export interface ServiceProviderEducationCreditDto {
  enrollmentId: number;
  enrollmentName: string;
  currencyId: number;
  totalCredit: number;
  totalReceipt: number;
  transactions: ServiceProviderEducationCreditTransactionDto[];

  expanded: boolean;
}

export interface ServiceProviderEducationCreditTransactionDto {
  id: number;
  date: string;
  type: number;
  relatedBranchName: string;
  relatedBranchId: number | null;
  executorUserName: string;
  executorUserId: number;
  accountName: string;
  accountId: number | null;
  currencyId: number;
  amount: number;
  note: string;
}

@Component({
  selector: 'utclub-credit-service-provider-education',
  templateUrl: './credit-service-provider-education.component.html',
  styleUrls: ['./credit-service-provider-education.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
    fadeInUp400ms,
    stagger40ms
  ]
})
export class CreditServiceProviderEducationComponent {
  serviceProviderId: number;
  totalCredit: number;
  totalReceipt: number;

  serviceProviderIdControl: FormControl = new FormControl();

  data: ServiceProviderEducationCreditDto[];
  loading = false;
  constructor(
    private apiService: ApiService,
    private eventBusService: EventBusService,
    private cdr: ChangeDetectorRef,
    private lookup: LookupService,
    private snackbar: MatSnackBar
  ) {}

  onChangeServiceProvider(event) {
    this.serviceProviderId = event;
    this.totalCredit = 0;
    this.totalReceipt = 0;
    this.loadData();
  }

  loadData() {
    this.loading = true;
    this.cdr.detectChanges();

    var url = `accounting-service/credit/service-provider-education-credits/${this.serviceProviderId}`;
    this.apiService.get<Response<ServiceProviderEducationCreditDto[]>>(url).subscribe({
      next: (response) => {
        response.data.forEach((item: ServiceProviderEducationCreditDto) => {
          this.totalCredit += item.totalCredit;
          this.totalReceipt += item.totalReceipt;
        });

        this.data = response.data;
        this.loading = false;
        this.cdr.detectChanges();
      },
      error: (error) => {
        this.snackbar.open(error.error.message, 'Close', { duration: 5000 });
      }
    });
  }

  get serviceProviders() {
    return this.lookup.serviceProviders$;
  }
}
