import { Component, ContentChildren, OnInit, QueryList, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatColumnDef, MatTable, MatTableDataSource } from '@angular/material/table';
import { PagedResponse, Response } from 'src/app/models/response.model';
import { ApiService } from 'src/app/services/api.service';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { EventBusService, EventData } from 'src/app/services/event-bus.service';
import { UtclubTabInterface } from 'src/app/components/utclub-tab/utclub-tab.component';
import { EnrollmentDebitsComponent } from '../enrollment-debits/enrollment-debits.component';
import { StudentDebitsByServiceProviderComponent } from './student-debits-by-service-provider/student-debits-by-service-provider.component';
import { EnrollmentDebitByServiceProviderDto } from 'src/app/api/models/enrollmentDebitByServiceProviderDto';

export interface ServiceProviderDto {
  id: number;
  name: string;
}

export interface CustomEnrollmentDebitByServiceProviderDto extends EnrollmentDebitByServiceProviderDto {
  transactionsDataSource: MatTableDataSource<any>;
}

@Component({
  selector: 'utclub-debit-service-provider-report',
  templateUrl: './service-provider-debits.component.html',
  styleUrls: ['./service-provider-debits.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    fadeInUp400ms,
    stagger40ms
  ]
})
export class ServiceProviderDebitsComponent implements OnInit {
  dataSource: MatTableDataSource<any> | null = null;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  loading: boolean = false;
  columns: string[] = ['enrollmentName', 'totalDebit', 'totalPayment', 'expand'];
  subColumns: string[] = ["date", "type", "relatedBranch", "executorUser", "account", "amount", "detail", "note"];

  pageSize: number = 50;
  pageIndex: number = 0;
  totalDebit: number = 0;
  totalPayment: number = 0;
  currencyId: number = 0;

  expandedElement: any | null;

  serviceProviders: ServiceProviderDto[] = [];
  serviceProviderId = 0;
  constructor(
    private matDialog: MatDialog,
    private apiService: ApiService,
    private eventBusService: EventBusService
  ) { }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    this.apiService.get<Response<ServiceProviderDto[]>>('accounting-service/serviceprovider').subscribe(response => {
      this.serviceProviders = response.data;
    });
  }

  onChangeServiceProvider(event) {
    this.serviceProviderId = event;
    this.paginator.pageIndex = this.pageIndex;
    this.paginator.pageSize = this.pageSize;
    this.totalDebit = 0;
    this.totalPayment = 0;
    this.loadData();
  }

  loadData() {
    this.apiService.post<PagedResponse<CustomEnrollmentDebitByServiceProviderDto>>('accounting-service/debit/enrollment-debits-by-service-provider', {
      serviceProviderId: this.serviceProviderId,
      page: this.paginator?.pageIndex ?? this.pageIndex,
      pageSize: this.paginator?.pageSize ?? this.pageSize
    }).subscribe(response => {

      response.data.forEach((item: CustomEnrollmentDebitByServiceProviderDto) => {
        item.transactionsDataSource = new MatTableDataSource(item.transactions);
        this.totalDebit += item.totalDebit;
        this.totalPayment += item.totalPayment;
        this.currencyId = item.currencyId;
      });

      this.dataSource = new MatTableDataSource(response.data);
      this.dataSource.paginator = this.paginator;
    });
  }

  rowClicked(row) {
    var tabInterface: UtclubTabInterface = {
      label: row.serviceProviderName + " Borc Raporu",
      component: StudentDebitsByServiceProviderComponent,
      data: {
        serviceProviderId: row.serviceProviderId
      },
      id: "student-debits-by-service-provider-" + row.serviceProviderId,
      singleTab: true,

    };
    this.eventBusService.emit(new EventData('tab-added', tabInterface));
  }
}
