import { Component, OnInit } from '@angular/core';
import { PopoverRef } from '../popover/popover-ref';
import { AuthService } from 'src/app/services/auth.service';
import { ProfileComponent } from 'src/app/pages/identity/profile/profile.component';
import { EventBusService, EventData } from 'src/app/services/event-bus.service';
import { UtclubTabInterface } from 'src/app/components/utclub-tab/utclub-tab.component';

@Component({
  selector: 'vex-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {

  constructor(
    private readonly popoverRef: PopoverRef,
    private authService: AuthService,
    private eventBusService: EventBusService
  ) { }

  ngOnInit(): void {
  }

  close(): void {
    /** Wait for animation to complete and then close */
    setTimeout(() => this.popoverRef.close(), 250);
  }

  profile() {
    var page: UtclubTabInterface = {
      id: 'profile',
      label: 'Profil',
      component: ProfileComponent,
      data: {},
      singleTab: true
    };

    this.eventBusService.emit(new EventData('tab-added', page));
    this.close();
  }

  logout(): void {
    this.authService.logout();
    this.close();
  }
}
