import { ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { ApiService } from 'src/app/services/api.service';
import { InvoiceFormSelectCustomerComponent } from './invoice-form-select-customer/invoice-form-select-customer.component';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  EnrollmentCreditBalanceDto,
  InvoiceFormSelectStudentsComponent
} from './invoice-form-select-students/invoice-form-select-students.component';
import { environment } from 'src/environments/environment';
import { DatePipe, DecimalPipe } from '@angular/common';
import { InvoiceDto } from 'src/app/api/models/invoiceDto';
import { InvoiceFormSendEmailComponent } from './invoice-form-send-email/invoice-form-send-email.component';
import { CurrencyDto } from 'src/app/api/models/currencyDto';
import { EntityService } from 'src/app/services/entity.service';

export interface EnrollmentCreditInfoDto {
  enrollmentId: number;
  enrollmentName: string;
  credit: number;
  receipt: number;
  visaStatus: number;
  programStartDate: string;
  schoolCountryId: number;
}

@Component({
  selector: 'utclub-invoice-form',
  templateUrl: './invoice-form.component.html',
  styleUrls: ['./invoice-form.component.scss'],
  animations: [fadeInUp400ms, stagger40ms]
})
export class InvoiceFormComponent {
  @Input() id: number;

  currencyName: string = '';
  currencies: CurrencyDto[];
  currencyBanks: any[] = [];

  model: any = {};

  form = new FormGroup({
    currencyId: new FormControl(0),
    invoiceStatus: new FormControl(0),
    date: new FormControl(''),
    name: new FormControl(''),
    address: new FormControl(''),
    taxNumber: new FormControl(''),
    phoneNumber: new FormControl(''),
    email: new FormControl(''),
    city: new FormControl(''),
    country: new FormControl(''),
    zip: new FormControl(''),
    items: new FormArray([])
  });

  invoiceStatuses: { id: number; name: string }[] = [
    { id: 0, name: 'Odeme Bekliyor' },
    { id: 1, name: 'Odeme Alindi' },
    { id: 2, name: 'Taslak' },
    { id: 3, name: 'Batik' }
  ];

  get items(): FormArray {
    return this.form.get('items') as FormArray;
  }

  constructor(
    private entityService: EntityService,
    private apiService: ApiService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private snackbar: MatSnackBar,
    private datePipe: DatePipe,
    private numberPipe: DecimalPipe
  ) {}

  async ngOnInit() {
    if (!this.id) {
      this.model.date = new Date();
      this.form.patchValue({
        date: this.model.date
      });
    }
    this.currencies = await this.entityService.getEntities('Currency');

    if (this.id) {
      this.loadInvoice(this.id);
    }

    this.form.valueChanges.subscribe((data) => {
      var total = 0;
      if (data.items.length > 0) {
        data.items.forEach((item) => {
          total += item.quantity.toFixed(2) * item.price.toFixed(2);
        });
      }
      // this.model.total = this.numberPipe.transform(total, '1.2-2');
      this.model.total = total.toFixed(2);
    });
  }

  print() {
    window.open(environment.API_URL + 'api/print-service/invoice/' + this.id + '/html', '_blank');
  }

  pdf() {
    window.open(environment.API_URL + 'api/print-service/invoice/' + this.id + '/pdf', '_blank');
  }

  save() {
    if (this.form.invalid) return;

    if (!this.model.currencyId) {
      this.snackbar.open('Please select currency', 'Close', {
        duration: 3000
      });
      return;
    }

    if (this.id == undefined || this.id == null) {
      this.apiService.post('accounting-service/invoice', this.form.value).subscribe((data) => {
        this.loadInvoice(data.data);
        this.id = data.data;
      });
    }
    if (this.id) {
      this.apiService.put('accounting-service/invoice/' + this.id, this.form.value).subscribe((data) => {
        this.loadInvoice(this.id);
      });
    }
  }

  loadInvoice(invoiceId) {
    this.apiService.get('accounting-service/invoice/' + invoiceId).subscribe((response) => {
      this.form = new FormGroup({
        currencyId: new FormControl(0),
        invoiceStatus: new FormControl(0),
        date: new FormControl(''),
        name: new FormControl(''),
        address: new FormControl(''),
        taxNumber: new FormControl(''),
        phoneNumber: new FormControl(''),
        email: new FormControl(''),
        city: new FormControl(''),
        country: new FormControl(''),
        zip: new FormControl(''),
        items: new FormArray([])
      });
      for (const key in response.data) {
        if (key == 'id' || key == 'items') continue;
        this.model[key] = response.data[key];
        this.form.patchValue({
          [key]: response.data[key]
        });
      }

      this.form.valueChanges.subscribe((data) => {
        var total = 0;
        if (data.items.length > 0) {
          data.items.forEach((item) => {
            total += item.quantity * item.price;
          });
        }
        // this.model.total = this.numberPipe.transform(total, '1.2-2');
        this.model.total = total.toFixed(2);
      });
      var itemsForm: FormArray = this.form.controls['items'];
      response.data.items.forEach((item) => {
        itemsForm.push(
          new FormGroup({
            id: new FormControl(item.id),
            name: new FormControl(item.name),
            quantity: new FormControl(item.quantity),
            price: new FormControl(item.price)
          })
        );
      });
      this.currencyUpdated();
      this.cdr.detectChanges();
    });
  }

  currencyUpdated() {
    this.currencyName = this.currencies.find((x) => x.id == this.model.currencyId).name;
    this.form.patchValue({
      currencyId: this.model.currencyId
    });
    this.loadCurrencyBanks();
  }

  invoiceStatusUpdated() {
    this.form.patchValue({
      invoiceStatus: this.model.invoiceStatus
    });
    this.cdr.detectChanges();
  }

  loadCurrencyBanks() {
    this.apiService
      .post('accounting-service/account/filter', {
        currencyId: this.model.currencyId,
        onlyHaveBankInfo: true,
        paging: false
      })
      .subscribe((response) => {
        this.currencyBanks = response.data;
        this.cdr.detectChanges();
      });
  }

  selectCustomer() {
    this.dialog
      .open(InvoiceFormSelectCustomerComponent, {
        width: '700px'
      })
      .afterClosed()
      .subscribe((data) => {
        if (data) {
          for (const key in data) {
            if (key == 'id') continue;
            this.model[key] = data[key];
            this.form.patchValue({
              [key]: data[key]
            });
          }
          this.form.markAsDirty();
          this.cdr.detectChanges();
        }
      });
  }

  selectStudents() {
    this.dialog
      .open(InvoiceFormSelectStudentsComponent, {
        width: '700px'
      })
      .afterClosed()
      .subscribe((data) => {
        if (data) {
          data.forEach((item: EnrollmentCreditBalanceDto) => {
            const items = this.form.get('items') as FormArray;
            items.push(
              new FormGroup({
                id: new FormControl(null),
                name: new FormControl(
                  `#${item.enrollmentId} | ${item.enrollmentName} | Program Start Date: ${this.datePipe.transform(item.programStartDate, 'dd-MM-yyyy')} | (${item.receipt} / ${item.credit})`
                ),
                price: new FormControl(item.credit - item.receipt),
                quantity: new FormControl(1)
              })
            );
          });

          this.form.markAsDirty();
          this.cdr.detectChanges();
        }
      });
  }

  addLine() {
    var itemsForm: FormArray = this.form.controls['items'];
    itemsForm.push(
      new FormGroup({
        id: new FormControl(null),
        name: new FormControl('Aciklama giriniz'),
        quantity: new FormControl(1),
        price: new FormControl(0)
      })
    );
    this.form.markAsDirty();
  }
  removeLine(index: number) {
    var itemsForm: FormArray = this.form.controls['items'];
    itemsForm.removeAt(index);
    this.form.markAsDirty();
  }

  openEmailForm() {
    this.dialog
      .open(InvoiceFormSendEmailComponent, {
        width: '700px',
        data: {
          invoiceId: this.id
        }
      })
      .afterClosed()
      .subscribe((data) => {});
  }
}
