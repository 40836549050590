import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgIterable,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import { CustomFormlyFieldConfig } from '../custom-formly-field-config';
import { MatSelect } from '@angular/material/select';
import { EventBusService, EventData } from 'src/app/services/event-bus.service';
import { Observable, ReplaySubject, Subject, Subscription, map, take, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'formly-enum-select',
  template: `
    <ng-container>
      <mat-form-field appearance="outline" style="width: 100%;">
        <mat-label> {{ field.props.label }} </mat-label>

        <mat-select
          #input
          [multiple]="field.props.multiple"
          [required]="field.props.required"
          [formControl]="control"
          [formlyAttributes]="field"
          (opened)="onOpened()"
          (closed)="onClosed()"
          #singleSelect>
          <mat-option>
            <ngx-mat-select-search
              [closeSvgIcon]="'mat:close'"
              [formControl]="searchCtrl"
              placeholderLabel="Ara..."></ngx-mat-select-search>
          </mat-option>

          <mat-option *ngIf="!field.props.required && !searchCtrl.value" [value]="null"> Seçiniz </mat-option>

          <mat-option *ngFor="let item of filteredItems | async" [value]="item.value">
            {{ item.label }}
          </mat-option>
        </mat-select>

        <ng-template #loading>
          <mat-spinner diameter="20"></mat-spinner>
        </ng-template>
      </mat-form-field>
    </ng-container>
  `
})
export class EnumFieldComponent extends FieldType<CustomFormlyFieldConfig> implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('input') input: MatSelect;

  searchCtrl = new FormControl();

  items: { value: any; label: string }[];
  filteredItems: ReplaySubject<{ value: any; label: string }[]> = new ReplaySubject<{ value: any; label: string }[]>();
  _onDestroy = new Subject<void>();
  constructor(
    private eventBusService: EventBusService,
    private translateService: TranslateService
  ) {
    super();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  ngAfterViewInit(): void {
    this.setInitialValue();
  }

  setInitialValue() {
    this.filteredItems.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
      this.input.compareWith = (o1: any, o2: any) => {
        return o1 && o2 && o1 === o2;
      };
    });
  }

  filterItems() {
    if (!this.items) {
      return;
    }
    // get the search keyword
    let search = this.searchCtrl.value;
    if (!search) {
      this.filteredItems.next(this.items.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredItems.next(this.items.filter((x) => x.label.toLowerCase().indexOf(search) > -1));
  }

  async ngOnInit() {
    if (!this.field.props.valueProp) {
      this.field.props.valueProp = 'id';
    }
    if (!this.field.props.labelProp) {
      this.field.props.labelProp = 'name';
    }

    if (this.field.props.enum) {
      this.setEnum();
    } else {
      throw new Error('enum is required');
    }
  }

  setEnum() {
    var items = [];
    for (let key in this.field.props.enum.values) {
      items.push({
        value: this.field.props.enum.values[key],
        label: this.translateService.instant(`enum.${this.field.props.enum.key}.${this.field.props.enum.values[key]}`)
      });
    }
    this.items = items;
    this.filteredItems.next(this.items.slice());
  }

  onOpened() {
    this.searchCtrl.setValue('');
  }

  onClosed() {
    this.searchCtrl.setValue('');
  }

  get control() {
    return this.formControl as FormControl;
  }
}
